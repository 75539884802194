import { generateAction } from "../redux-helpers";
import { CLEAR_FORM, GET_ARUBA_CONTROLLERS, SUBMIT_CONTROLLER_ADDRESS, SUBMIT_PROVISION } from "../actions-index";
import { transformControllers, transformAccessPointGroups } from "./transform";
import { formInputs } from "./inputs";
import { API } from "aws-amplify";

const { accessPointGroup, accessPointName } = formInputs

export function getArubaControllers() {
  return async (dispatch) => {
    dispatch(generateAction(GET_ARUBA_CONTROLLERS, { status: "loading" }));
    try {
      let response = await API.get(process.env.REACT_APP_MAIN_API_NAME, '/getcontrollers')    
      const transformedResponse = transformControllers(response.data);
      dispatch(
        generateAction(GET_ARUBA_CONTROLLERS, { list: transformedResponse })
      );
    } catch (err) {
      console.error("Error getting controllers: ", err);
      dispatch(generateAction(GET_ARUBA_CONTROLLERS, { status: "error" }));
    }
  };
}

function determineRetryValues(hitRetry = false, storedPrimaryFieldsForm = {}, currentValues = {}) {
  let retryCount = 0
  if(hitRetry) {
    const storedValues = Object.assign({}, storedPrimaryFieldsForm?.values)
    const oldValues = {
      controller: storedValues[formInputs.controller.name],
      address: storedValues[formInputs.macAddress.name]
    } 
    const changeInForm = oldValues.controller !== currentValues.controller || oldValues.address !== currentValues.address
    
    retryCount = changeInForm ? 0 : (storedPrimaryFieldsForm?.retryCount || 0) + 1
  }
   
  return {
    hitRetry,
    retryCount
  }
}

export function submitPrimaryFields(values = {}) {
  let body = {};
  body.controller = values[formInputs.controller.name]
  body.address = values[formInputs.macAddress.name] 

  return async (dispatch, getState) => {
    const { wifiForm = { primaryFieldsForm: {} }} = getState() || { wifiForm: { primaryFieldsForm: {} }}
    const retryLogic = determineRetryValues(values?.hitRetry, wifiForm?.primaryFieldsForm, body)
    let response
    dispatch(generateAction(SUBMIT_CONTROLLER_ADDRESS, { status: "loading", values }));
    let errors = [];
    if (!body.controller) {
      errors.push({ field: formInputs.controller.name, message: `Option required for ${formInputs.controller.label}.` });
    }
    const macAddressPattern = new RegExp(formInputs.macAddress.pattern);
    if (!body.address) {
      errors.push({ field: formInputs.macAddress.name, message: `Input required for ${formInputs.macAddress.label}.` });
    } else if (!macAddressPattern.test(body.address)) {
      errors.push({ field: formInputs.macAddress.name, message: `Invalid mac address submitted for ${formInputs.macAddress.label}. Please verify all characters are letters a-f or numbers, separated by colon every two characters.` });
    // eslint-disable-next-line eqeqeq
    } else if (body.address.length != formInputs.macAddress.maxLength) {
      errors.push({ field: formInputs.macAddress.name, message: `Invalid mac address submitted for ${formInputs.macAddress.label}. Expected ${formInputs.macAddress.maxLength} characters, received ${body.address.length}.` });
    }

    try {
      if (errors.length > 0) {
        throw new Error(`Missing fields`);
      }
      response = await API.get(process.env.REACT_APP_MAIN_API_NAME, `/verifymac/${body.controller}/${body.address}`);
    
      if (response.data.validMacAddress) {
        response = await API.get(process.env.REACT_APP_MAIN_API_NAME, `/getapgroups/${body.controller}`);
        let formatedResponse = [];
        for (let idx in response.data) {
          let obj = {};
          obj.value = response.data[idx];
          obj.label = response.data[idx];
          formatedResponse.push(obj);
        }
        let transformedData = transformAccessPointGroups(formatedResponse);
        const defaultAPGroup = response.data[0];
        dispatch(generateAction(SUBMIT_CONTROLLER_ADDRESS, { list: transformedData, defaultAPGroup, values }))

      } else {
        if (response.data.errorMessages.length > 0) {
          let stringifyMessage = response.data.errorMessages.join(' ');
          dispatch(generateAction(SUBMIT_CONTROLLER_ADDRESS, { ...retryLogic, values, status: "error", errorFields: stringifyMessage }));
        }
        return;
      }
    } catch (error) {
      console.error("Error posting address and controller: ", error);
      dispatch(generateAction(SUBMIT_CONTROLLER_ADDRESS, { ...retryLogic, values, status: "error", errorFields: errors }));
    }
  };
}

export function submitProvision(values = {}) {

  return async (dispatch, getState) => {
    const { wifiForm = { primaryFieldsForm: {} }} = getState() || { wifiForm: { primaryFieldsForm: {} }};
    const storedValues = wifiForm?.primaryFieldsForm.values;
    
    dispatch(generateAction(SUBMIT_PROVISION, { status: "loading" }));

    let body = {};
    body.masterController = storedValues.controller;
    body.macAddress = storedValues.address;
    body.apGroup = values[accessPointGroup.name];
    body.apName = values[accessPointName.name];
    const retryLogic = determineRetryValuesForProvisioning(values?.hitRetry, wifiForm?.provision, values)

    let errors = [];
    if (!body.apGroup) {
      errors.push({ field: accessPointGroup.name, message: `Option required for ${accessPointGroup.label}.` });
    }
    if (!body.apName) {
      errors.push({ field: accessPointName.name, message: `Input required for ${accessPointName.label}.` });
    }

    try {
  
      if (errors.length > 0) {
        throw new Error(`Missing fields`);
      }
      let response = await API.post(process.env.REACT_APP_MAIN_API_NAME, `/provisionaccesspoint`, { body });
      if (response.status === 'success') {
        dispatch(generateAction(SUBMIT_PROVISION, { status: 'success' }));
      } else if(response.data.errorMessages.length > 0) {
          dispatch(generateAction(SUBMIT_PROVISION, { ...retryLogic, values, status: "error", errorFields: response.data.errorMessages.join(' ') }));
      } else {
        throw Error('Something went wrong provisioning')
      }
    } catch (error) {
      console.error("Error posting address and controller: ", error);
      dispatch(generateAction(SUBMIT_PROVISION, { ...retryLogic, values, status: "error", errorFields: errors }));
    }
  };
}

function determineRetryValuesForProvisioning(hitRetry = false, storedProvision = {}, currentValues = {}) {
  let retryCount = 0
  if(hitRetry) {
    const storedValues = Object.assign({}, storedProvision?.values)
    
    const changeInForm = storedValues[accessPointGroup.name] !== currentValues[accessPointGroup.name]
    || storedValues[accessPointName.name] !== currentValues[accessPointName.name]

    retryCount = changeInForm ? 0 : (storedProvision?.retryCount || 0) + 1
  }
   
  return {
    hitRetry,
    retryCount
  }
}

export function clearFormFields() {
  return dispatch => {
    dispatch(generateAction(CLEAR_FORM, { clear: true }));
  }
}