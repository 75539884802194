import { lazy } from 'react';
import LogIn from '../Authentication/components/LogIn';
import LogOut from '../Authentication/components/LogOut';
import Unauthorized from './views/Unauthorized';

const Form = lazy(() => import('../Form/containers/'));

const PATHS = [
    {
        title: "Aruba Access Point Provisioning Tool",
        path: '/',
        Component: Form,
    }
];

const authenticationFlowPaths = [
    {
        path: '/login',
        Component: LogIn 
    },
    {
        path: '/logout',
        Component: LogOut 
    },
    {
        path: '/unauthorized',
        Component: Unauthorized 
    }
]

export {
    authenticationFlowPaths,
    PATHS as default
}