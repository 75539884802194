import React from 'react';
import { Mui } from '@osu/react-ui';
import AuthButton from '../containers/AuthButton';

const useStyles = Mui.makeStyles((theme) => {
    return {
        appBar: {
          top: '42px',
          zIndex: theme.zIndex.appBar
        },
        root: {
          flexGrow: 1,
          paddingBottom: theme.spacing(2),
          [theme.breakpoints.up('sm')]: {
            paddingBottom: theme.spacing()
          }
        },
        menuButton: {
          marginRight: theme.spacing(2),
        },
        title: {
          flexGrow: 1,
        },
        toolbar: {
          display: 'flex',
          flexDirection: `column`,
          alignItems: 'flex-start',
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          [theme.breakpoints.up('sm')]: {
            flexDirection: `row`,
            alignItems: 'center'
          }
        }
      }
});

export default function Navbar(props = {}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Mui.AppBar color="inherit" className={classes.appBar} position="fixed">
        <Mui.Toolbar className={classes.toolbar}>
          <Mui.Typography component="span" variant="h6" className={classes.title}>
            Ohio State WiFi Provisioning
          </Mui.Typography>
          <AuthButton />
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Toolbar />
    </div>
  );
}