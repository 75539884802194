export const SET_USER = 'SET_USER';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const USER_STATUS = 'USER_STATUS';
export const FETCH_USER = 'FETCH_USER';
export const GET_ARUBA_CONTROLLERS = 'GET_ARUBA_CONTROLLERS';
export const SUBMIT_CONTROLLER_ADDRESS = 'SUBMIT_CONTROLLER_ADDRESS';
export const SUBMIT_PROVISION = 'SUBMIT_PROVISION';
export const CLEAR_FORM = 'CLEAR_FORM'

/* Export each action file below */
export * from './Authentication/actions';
export * from './Form/actions';