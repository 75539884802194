import React, { useEffect } from "react";
import { connect } from "react-redux";
import useAmplifyHub from "../Common/hooks/useAmplifyHub";
import { useIdleTimer } from "react-idle-timer";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { authenticationFlowPaths } from "../Routes/paths";
import { useFirstRender, usePrevious } from "@osu/react-ui";
import { PERMITTED_ROLE } from "../util/constants";

const TIMEOUT = 60000 * (parseInt(process.env.REACT_APP_TIMEOUT) || 120);

function WithAuthentication(Component) {
  function _withAuthentication(props = {}) {
    const { status, authentication, ...rest } = props
    const location = useLocation()
    const pathname = location?.pathname
    const history = useHistory()
    const firstRender = useFirstRender()
    const applicationRoles = authentication?.user?.applicationRoles
    const isPermittedRole = PERMITTED_ROLE && applicationRoles && applicationRoles.includes(PERMITTED_ROLE)
    const previous = usePrevious({ status, isPermittedRole })
    const { status: previousStatus, isPermittedRole: prevIsPermittedRole } = previous?.current || {}
    const publicRoute = authenticationFlowPaths.map(({ path }) => path).includes(pathname)
    const pushHistory = history.push

    useEffect(() => {
      const newSuccessState = (previousStatus !== status) && (status === 'success')
      const nowPermittedRole = !prevIsPermittedRole && !!isPermittedRole
     
      if((newSuccessState && nowPermittedRole) && (pathname === '/unauthorized')) {
        pushHistory('/')
      }
    }, [pushHistory, isPermittedRole, pathname, prevIsPermittedRole, previousStatus, publicRoute, status])

    let redirectPath = '/'
    if(!publicRoute) {
      redirectPath = pathname
      if(location?.search) {
        redirectPath += location?.search
      }
      if(location?.hash) {
        redirectPath += location?.hash
      }
    }
    
    const { handleSignOut, handleSignIn } = useAmplifyHub();

    useEffect(() => {
      if(firstRender && pathname !== '/logout') {
        handleSignIn(redirectPath, { doNotRedirect: true })
      }
    }, [firstRender, handleSignIn, pathname, redirectPath])

    const handleOnIdle = (event) => {
      console.log("user is idle", event);
      console.log("last active", getLastActiveTime());
      history.push('/logout')
    };
    const { getLastActiveTime } = useIdleTimer({
      timeout: TIMEOUT,
      onIdle: handleOnIdle,
    });
    
    const authorized = status === 'success' && applicationRoles && applicationRoles.includes("AP-PROVISION-USER")

    if(publicRoute || authorized) {
      return <Component handleSignIn={handleSignIn} handleSignOut={handleSignOut} authorized={authorized} {...rest} />
    }  

    if(!status && pathname !== '/login') {
      return <Redirect to={{ pathname: '/login', state: { redirectPath } }} />
    }

    return <Redirect to={{ pathname: '/unauthorized', state: { redirectPath } }}  />
  }

  const mapStateToProps = (state) => {
    return {
      status: state.authentication?.status,
      authentication: state.authentication,
    };
  };

  return connect(mapStateToProps, undefined)(_withAuthentication);
}

export default WithAuthentication;
