import { CLEAR_FORM, GET_ARUBA_CONTROLLERS, SUBMIT_CONTROLLER_ADDRESS, SUBMIT_PROVISION } from "../actions-index";

export function wifiForm(state = {}, action = {}) {
  let { list = [], status = "", hitRetry, errorFields, values, retryCount = 0, clear = false, defaultAPGroup } = action?.payload || {};
  
  switch (action.type) {
    case GET_ARUBA_CONTROLLERS:
      return {
        ...state,
        controllers: {
          status: status ? status : list.length > 0 ? "success" : undefined,
          options: list
        },
      };
    case SUBMIT_CONTROLLER_ADDRESS:
      return {
        ...state,
        primaryFieldsForm: {
          errorFields,
          status: status ? status : list.length > 0 ? "success" : undefined,
          hitRetry,
          retryCount,
          values
        },
        accessPointGroups: {
          status: status ? status : list.length > 0 ? "success" : undefined,
          options: list,
          defaultAPGroup
        },
      };
    case SUBMIT_PROVISION: {
      return {
        ...state,
        provision: {
          status,
          errorFields,
          hitRetry,
          retryCount,
          values
        }
      }
    }
    case CLEAR_FORM: {
      if(clear) {
        return {}
      }
      return state
    }
    default:
      return state;
  }
}
