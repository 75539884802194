import React, { Fragment, Suspense, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import NoComponentFound from "../views/NoComponentFound";
import PATHS, { authenticationFlowPaths } from "../paths";
import RenderableView from "./RenderableView";
import { AppContext } from "../../App/context";

function Routes() {
  const { authorized } = useContext(AppContext)

  let RoutesToRender = PATHS.map((pathInformation, index) => (
    <Route
      key={`route${encodeURIComponent(pathInformation.title)}#${index}`}
      exact
      path={pathInformation.path}
      render={(renderProps) => {
        return <RenderableView
            pathInformation={pathInformation}
            renderProps={renderProps}
          />
      }}
    />))

  return (
    <Fragment>
      <Suspense fallback={<div>Loading</div>}>
        <Switch>
          {authenticationFlowPaths.map(({ path, Component }, index) =>  <Route key={`route${encodeURIComponent(path)}#${index}`} exact path={path} render={() => <Component />} />)}
          {authorized && RoutesToRender}
          <Route render={() => <NoComponentFound />} />
        </Switch>
      </Suspense>
    </Fragment>
  );
}

export default Routes;