import React from "react";
import PropTypes from "prop-types";
import OSUNavbar from "react-osu-navbar";
import { withOSUTheming } from "@osu/react-ui";
import { AppProvider } from "../context";
import Main from "../../Main/components";
import Navbar from "../../Navbar/components";
import { useAppContext } from "../hooks/useAppContext";
import withAuthentication from "../../Authentication/withAuthentication";
import Footer from '../../Footer/components'

function App(props = {}) {
  const { authorized, handleSignIn, authStatus, handleSignOut } = props;
  const appContext = useAppContext({
    authStatus,
    authorized,
    handleSignIn,
    handleSignOut,
  });

  return (
    <AppProvider value={appContext}>
      <div className={`App`}>
        <OSUNavbar/>
        <Navbar />
        <Main navHeight={appContext.navHeight} id="page-content" />
        <Footer />
      </div>
    </AppProvider>
  );
}

App.propTypes = {
  handleSignIn: PropTypes.func,
  handleSignOut: PropTypes.func,
  authorized: PropTypes.bool,
  authStatus: PropTypes.string
};

export default withOSUTheming(withAuthentication(App))
