const inputTypes = {
    SELECT: 'select',
    TEXT: 'text',
    AUTOCOMPLETE: 'autocomplete'
}

const controller = {
    label: 'Aruba Mobility Conductor',
    id: `CONTROLLER`,
    name: `controller`,
    type: inputTypes.SELECT,
    required: true
}

const macAddress = {
    label: 'Access Point Mac Address',
    secondaryText: 'Numbers (0-9) and letters (a-f) only, colons will be added by default. All other characters will be ignored.',
    id: `MAC_ADDRESS`,
    name: `address`,
    maxLength: '17',
    type: inputTypes.TEXT,
    pattern: /^[0-9a-f]{1,2}([:])(?:[0-9a-f]{1,2}\1){4}[0-9a-f]{1,2}$/gmi,
    required: true
}

const accessPointGroup = {
    label: 'Access Point Group',
    id: `POINT_GROUP`,
    name: `point_group`,
    type: inputTypes.AUTOCOMPLETE,
    required: true
}
const accessPointName = {
    label: 'Access Point Name',
    id: `POINT_NAME`,
    name: `point_name`,
    type: inputTypes.TEXT,
    validation: '',
    required: true
}

let formInputs = {
    controller,
    macAddress,
    accessPointGroup,
    accessPointName
}
export {
    inputTypes,
    formInputs
}