import React, { useContext, useEffect } from "react";
import { Mui, PageHeadingContext } from "@osu/react-ui";

function Unauthorized(props = {}) {
  const { setHeading } = useContext(PageHeadingContext);

  useEffect(() => {
    setHeading && setHeading("Unauthorized");
  }, [setHeading]);

  return (
    <Mui.Typography className="margin-bottom-2">
      You are not authorized to see this page.
      For access, please contact the OCIO Networking Team through the help desk.
      <Mui.Box className="margin-top-2">
        Phone: <Mui.Link href="tel:6146884357">614-688-4357 (HELP)</Mui.Link>
      </Mui.Box>
      <Mui.Box>
        Email: <Mui.Link href="mailto:servicedesk@osu.edu">servicedesk@osu.edu</Mui.Link>
      </Mui.Box>
    </Mui.Typography>
  );
}

export default Unauthorized;
