import React, { useContext, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { PageHeadingContext } from '@osu/react-ui'
import { AppContext } from "../../App/context";

function LogIn(props = {}) {
  const { redirectPath } = props;
  const { setHeading } = useContext(PageHeadingContext)
  const { handleSignIn, authStatus } = useContext(AppContext)
  const location = useLocation()
  const [error, setError] = useState(false)

  useEffect(() => {
    let isMounted = true;
    if (!isMounted || error || ['loading', 'success'].includes(authStatus)) {
      return;
    }

    const signIn = async () => {
      try {
        await handleSignIn(location?.state?.redirectPath || redirectPath);
      } catch (error) {
        console.error("error", error);
        setError(true);
      }
    };

    signIn();
    
    return () => {
      isMounted = false;
    };
  });
  
  useEffect(() => {
    setHeading && setHeading('Log In')
  }, [setHeading])
  

  if (authStatus === "success") {
    return <Redirect to={redirectPath || '/'} />;
  } else if (authStatus === "loading") {
    return <div>Logging in</div>;
  }

  return (
    <div>
      There was an error logging you in.
      <button
        className="mt-2"
        variant="contained"
        color="primary"
        aria-label={`Retry logging in}`}
        onClick={() => handleSignIn(redirectPath)}
      >
        Log In
      </button>
    </div>
  );
}

export default LogIn;
