
import React from 'react';
import { Link } from 'react-router-dom';

function NoComponentFound(props = {}) {
    return <span>
       Oops! Are you lost? We couldn't find this page!
        <Link to="/">
            Home
        </Link>
    </span>
}

export default NoComponentFound