const PERMITTED_ROLE = process.env.REACT_APP_PERMITTED_ROLE || 'AP-PROVISION-USER'
const OSU_NAV_ID = 'osu_navbar'
const MAIN_ID = 'page-content'
const ACCESSIBLE_REFLOW_HEIGHT = `399px`
const TOC_WIDTH = `15rem`
export const STYLESHEET_LENGTH = document && document.styleSheets ? document.styleSheets.length : 99

export {
    PERMITTED_ROLE,
    OSU_NAV_ID,
    MAIN_ID,
    ACCESSIBLE_REFLOW_HEIGHT,
    TOC_WIDTH
}