import React from "react";
import { Mui, omitReduxProps } from "@osu/react-ui";
import { Link as RouterLink } from "react-router-dom";

function AuthButton(props = {}) {
  const { status, name, ...rest } = props;
  const remainingProps = omitReduxProps(rest);

  if (status === "loading") {
    return (
      <Mui.Typography variant="body2" {...remainingProps}>
        Logging in
      </Mui.Typography>
    );
  }

  if (status === "success") {
    return (
      <Mui.Box alignItems="center" display="flex" {...remainingProps}>
        {name && <Mui.Typography className="margin-right-1" variant="body2">{`${name} | `}</Mui.Typography>}
        <Mui.Link component={RouterLink} to="/logout">
          Log Out
        </Mui.Link>
      </Mui.Box>
    );
  }

  return (
    <Mui.Link component={RouterLink} to="/login">
      Log In
    </Mui.Link>
  );
}

AuthButton.defaultProps = {
  color: "inherit",
};

export default AuthButton;