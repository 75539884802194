import { useCallback, useEffect, useState } from 'react'
import { OSU_NAV_ID } from '../../util/constants'

export const useAppContext = (additionalContext = {}) => {
    const [navHeight, setNavHeight] = useState()

    const updateNavHeight = useCallback((newHeight) => {
        let root = document.documentElement    
        setNavHeight(newHeight)
        root.style.setProperty('--nav-height', newHeight + 'px')
    }, [])

    useEffect(() => {
        const navbar = document.getElementById(OSU_NAV_ID)

        if(navbar.clientHeight) {
            updateNavHeight(navbar.clientHeight)
        }
    }, [updateNavHeight])

    return {
        ...additionalContext,
        navHeight
    }
}