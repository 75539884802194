import { formInputs } from "./inputs";

function reformat(macAddress) {
  let newAddress = macAddress;
  if (macAddress.length > 2) {
    const two_chars_no_colons_regex = /([^:]{2}(?!:))/g;
    newAddress = macAddress.replace(two_chars_no_colons_regex, "$1:");
  }
  
  return newAddress;
}

function transformMacAddress(value) {
  let preparedValue = reformat(value);
  if (formInputs?.macAddress?.maxLength && preparedValue?.length > formInputs.macAddress.maxLength)

  preparedValue = preparedValue.substring(
      0,
      formInputs.macAddress.maxLength
    );
  preparedValue = preparedValue.toLowerCase();
  return preparedValue;
}

function transformControllers(data = []) {
  let formattedControllers = [];

  if (data?.length > 0) {
    formattedControllers = data
      .map((controller, idx) => ({
        value: controller,
        label: controller,
        id: `${controller}-controller-${idx}`,
      }));
  }

  return formattedControllers;
}

function transformAccessPointGroups(data = []) {
  let formattedGroups = [];

  if (data?.length > 0) {
    formattedGroups = data
      .filter((group) => group?.value && group?.label)
      .map((group, idx) => ({
        value: group.value,
        label: group.label,
        id: group.id || `${group.value}-ap-group-${idx}`,
      }));
  }

  return formattedGroups;
}

export const apNameRegex = /[^A-Za-z0-9-]/g

function transformAccessPointName(value) {
  let formattedName = value && value.replace(apNameRegex, "")
  return formattedName ? formattedName.toLowerCase() : '';
}

export { transformMacAddress, transformAccessPointGroups, transformControllers, transformAccessPointName };
