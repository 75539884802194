import React from "react";
import {
  PageHeading,
  withPageHeading,
  withTableOfContents,
  Mui,
} from "@osu/react-ui";
import Routes from "../../Routes/components";

const useStyles = Mui.makeStyles(() => {
  return {
    root: {
      padding: `2rem 1rem`
    }
  };
});

function Main(props = {}) {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <Mui.Container maxWidth="lg" className={`${className} ${classes.root}`.trim()} {...rest}>
      <header>
        <PageHeading component="h1" variant="h4" className="margin-bottom-2" />
      </header>
      <Routes />
    </Mui.Container>
  );
}

Main.defaultProps = {
  className: "",
  tabIndex: "-1",
  component: "main",
};

export default withPageHeading(withTableOfContents(Main));
