import { Mui } from "@osu/react-ui";
import React from "react";
import { Fragment } from "react";
import "../Footer.scss";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const useStyles = Mui.makeStyles((theme) => ({
  heading: {
    color: "white",
  },
  section: {
    display: `flex`,
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
}));

const FooterLink = Mui.withStyles((theme) => ({
  root: {
    color: "white",
    textDecoration: "underline",
    "&:hover": {
      color: "#9db8db",
    },
  },
}))(({ href = "", children, ...rest }) => (
  <Mui.Link variant="body2" href={href} {...rest}>
    {children}
    {href.startsWith("http") && (
      <Fragment>
        <OpenInNewIcon
          htmlColor="white"
          fontSize="inherit"
          className="new-window-icon"
          titleAccess="opens in new window"
        />
      </Fragment>
    )}
  </Mui.Link>
));

const Footer = () => {
  const classes = useStyles();
  return (
    <footer>
      <Mui.Container>
        <a
          aria-label="The Ohio State University home page"
          href="https://www.osu.edu/"
        >
          <img
            className="image-logo"
            alt=""
            src={require("../assets/osu-long-logo.png").default}
          />
        </a>
        <p>
          Ohio State WiFi Provisioning is managed by the{" "}
          <FooterLink href="https://it.osu.edu/" target="_blank">
            Office of the Chief Information Officer
          </FooterLink>
          .
        </p>
        <div className={`${classes.section} list-section-wrapper`}>
          <div className={`list-section`}>
            <Mui.Typography
              id="footer-support-resources"
              className={classes.heading}
              component="h2"
              variant="subtitle2"
            >
              Support Resources
            </Mui.Typography>
            <Mui.List
              className="unstylized"
              aria-labelledby="footer-support-resources"
              dense
            >
              <Mui.ListItem>
                <FooterLink
                  href="https://go.osu.edu/systemstatus"
                  target="_blank"
                >
                  System Status
                </FooterLink>
              </Mui.ListItem>
              <Mui.ListItem>
                <FooterLink target="_blank" href="https://go.osu.edu/it">
                  Find solutions and report issues
                </FooterLink>
              </Mui.ListItem>
              <Mui.ListItem>
                <FooterLink
                  href="https://go.osu.edu/nondiscrimination-notice"
                  target="_blank"
                >
                  Non-Discrimination Notice
                </FooterLink>
              </Mui.ListItem>
              <Mui.ListItem>
                <FooterLink href="https://osu.edu/privacy" target="_blank">
                  Privacy Statement
                </FooterLink>
              </Mui.ListItem>
            </Mui.List>
          </div>
          <div className={`list-section`}>
            <Mui.Typography
              id="footer-contact-us"
              className={classes.heading}
              component="h2"
              variant="subtitle2"
            >
              Contact Us
            </Mui.Typography>
            <Mui.List
              className="unstylized"
              aria-labelledby="footer-contact-us"
              dense
            >
              <Mui.ListItem>
                {"Phone: "}
                <FooterLink className="padding-left-1" href="tel:+16146884357">
                  614-688-4357 (HELP)
                </FooterLink>
              </Mui.ListItem>
              <Mui.ListItem>
                {"Email: "}
                <FooterLink
                  className="padding-left-1"
                  href="mailto:servicedesk@osu.edu"
                >
                  servicedesk@osu.edu
                </FooterLink>
              </Mui.ListItem>
              <Mui.ListItem>
                {"Accessibility Help Line: "}
                <FooterLink className="padding-left-1" href="tel:+16142925000">
                  614-292-5000
                </FooterLink>
              </Mui.ListItem>
            </Mui.List>
          </div>
        </div>
      </Mui.Container>
    </footer>
  );
};

export default Footer;
